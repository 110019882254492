import React from 'react';
import { PageBodyHomeHero } from 'src/slices/PageBodyHomeHero';
import { PageBodyHomeAccessories } from 'src/slices/PageBodyHomeAccessories';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var home = {
  name: 'Home',
  customType: 'page',
  description: 'Display prominent PVA projects in an interactive carousel.',
  example: [['PageBodyHomeHero', 0], ['PageBodyHomeAccessories', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        isTransparent: true
      });
    }
  }, {
    name: 'Home',
    preset: true,
    reasoning: 'Customizeable carousel to highlight PVA projects. Below the projects, this preset also displays a call to action and a featured news article.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyHomeHero, {
        nextSharesBg: [true],
        categories: [{
          title: 'Hospitality + Leisure',
          color: 'orange'
        }, {
          title: 'Interior Design',
          color: 'green'
        }, {
          title: 'Office + Healthcare',
          color: 'blue'
        }, {
          title: 'Residential',
          color: 'yellow'
        }, {
          title: 'Retail + Restaurants',
          color: 'pink'
        }],
        images: [{
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/dae0d171c7d1f15f5d309c504fec3488b8907178_0-kapolei-golf-course-clubhouse-renovation.jpg',
          projectTitle: 'Kapolei Golf Course Clubhouse Renovation',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/b3c42a97c8851479c463ae8791e5111ada0b4f08_1-makiki-modern-makeover.jpg',
          projectTitle: 'Makiki Modern Makeover',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/8e1f5edf16a17fcd62994d464cc761970eaf4ebd_0-bank-of-hawaii-at-pearlridge.jpg',
          projectTitle: 'Bank of Hawaii at Pearlridge',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/3046499be401eb6a3e849581939ab07c9d21d36e_0-manoa-retreat.jpg',
          projectTitle: 'Manoa Retreat',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg',
          projectTitle: 'Sushi Izakaya Dining: Shinn',
          projectHref: true
        }],
        primaryImageURL: "https://pvadev.cdn.prismic.io/pvadev/dba11a47caa75670e5d319fac411d112d5823ada_0-mokulua-hillside-renovation.jpg"
      }), React.createElement(PageBodyHomeAccessories, {
        calloutLink: true,
        featuredNewsHref: true,
        featuredNewsTitle: "PVA wins Hawaii Energy Award For Excellence in Energy-Efficiency Design and honorable mention at the AIA Honolulu Awards Gala",
        featuredNewsImageURL: "https://pvadev.cdn.prismic.io/pvadev/3bfcc5a0d7915f76cfa895ea780fa68b8dd8f30c_news-award.jpg",
        featuredNewsCategory: "Company Updates",
        featuredNewsDate: "September 24th, 2019",
        calloutButtonText: "See our work",
        calloutTextHTML: "<h1>Over 25 years of experience as one of Hawaii's most highly regarded architecture firms.</h1>",
        nextSharesBg: [true]
      }), React.createElement(PageBodyFooter, null));
    }
  }]
};