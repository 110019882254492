import React from 'react';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
import { PageBodyTeamMembers } from 'src/slices/PageBodyTeamMembers';
import { PageBodyHorizontalNavigation } from 'src/slices/PageBodyHorizontalNavigation';
export var team = {
  name: 'Team',
  customType: 'page',
  description: 'Highlight team members at PVA with an additional interesting facts.',
  example: [['PageBodyHorizontalNavigation', 0], ['PageBodyTeamMembers', 0], ['PageBodyTeamMembers', 1]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'Horizontal Navigation',
    preset: true,
    reasoning: 'Links to other related pages on the site. The page title is always displayed even if no links are present.',
    render: function render() {
      return React.createElement(PageBodyHorizontalNavigation, {
        title: "about",
        rootHref: "/"
      }, React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "About PVA"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Team"));
    }
  }, {
    name: 'Team Members',
    slices: ['PageBodyTeamMembers'],
    recommendedSlices: ['PageBodyTeamMembers'],
    reasoning: 'Highlight various team members of varying prominence by changing the size of the Team Members slice.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyTeamMembers, {
        heading: "leadership",
        nextSharesBg: [true]
      }, React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Peter N. Vincent",
        title: "Managing Partner",
        imageURL: "https://images.prismic.io/pvadev/d5391f4a-cd83-4a3a-a213-ee38023a643d_2000px_Peter-389A0353.jpg?auto=compress,format",
        certifications: ['FAIA', 'NCARB'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Michael Subiaga",
        title: "Partner",
        imageURL: "https://images.prismic.io/pvadev/6e1dc21c-82ba-4947-9856-cc0ab9c625f1_2000px_Mike-389A0392.jpg?auto=compress,format",
        certifications: ['FAIA', 'NCARB'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Brad Ladwig",
        title: "Senior Associate / Project Designer",
        imageURL: "https://images.prismic.io/pvadev/4e8490b6-a9ea-4830-b9d4-bdb9ac2db5b8_2000px_Brad-389A0072.jpg?auto=compress,format",
        certifications: ['AIA'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Sherri Smith",
        title: "Associate / Design Technology Manager",
        imageURL: "https://images.prismic.io/pvadev/15c8b616-4548-46ac-86a2-76deeae1b785_2000px_Sherri-389A0149.jpg?auto=compress,format",
        certifications: ['AIA'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Minatsu \"Mitz\" Toguchi",
        title: "Associate / Senior Interio Designer",
        imageURL: "https://images.prismic.io/pvadev/14ab68f0-7cfc-468d-a0ef-24d8903fcfc7_2000px_Mitz-389A0207.jpg?auto=compress,format",
        certifications: ['IIDA'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Todd Hassler",
        title: "Senior Associate / Project Architect",
        imageURL: "https://images.prismic.io/pvadev/b3ce3fae-ca9f-42e0-9b95-40e54306bac0_2000px_Todd-389A0113.jpg?auto=compress,format",
        certifications: ['FAIA', 'NCARB', 'LEED AP'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      })), React.createElement(PageBodyTeamMembers, {
        heading: "team"
      }, React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Chris",
        title: "Job Title",
        imageURL: "https://images.prismic.io/pvadev/419580b7-72ea-4ae1-be2e-01537d4c6597_2000px_Chris-389A0422.jpg?auto=compress,format",
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "James",
        title: "Job Title",
        imageURL: "https://images.prismic.io/pvadev/624a3cd3-904a-4f68-8c7c-803677ce149b_2000px_James-389A0184.jpg?auto=compress,format",
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Lisa",
        title: "Job Title",
        imageURL: "https://images.prismic.io/pvadev/3869423d-c0d2-44b1-8d04-637c87219f75_2000px_Lisa-389A0022.jpg?auto=compress,format",
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};