import React from 'react';
import { PageBodyNewsIntro } from 'src/slices/PageBodyNewsIntro';
import { PageBodyPrevNext } from 'src/slices/PageBodyPrevNext';
import { PageBodyProjectCards } from 'src/slices/PageBodyProjectCards';
import { PageBodyText } from 'src/slices/PageBodyText';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var newsPost = {
  name: 'News Post',
  customType: 'page',
  description: 'News post with images, links to related projects, and next/previous article buttons.',
  example: [['PageBodyNewsIntro', 0], ['PageBodyText', 0], ['PageBodySmallImage', 0], ['PageBodyText', 0], ['PageBodyPrevNext', 1], ['PageBodyProjectCards', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        isTransparent: true
      });
    }
  }, {
    name: 'News',
    preset: true,
    reasoning: 'News post title, description, and large intro image to introduce the article to the reader.',
    render: function render() {
      return React.createElement(PageBodyNewsIntro, {
        title: "Kahala Crossroads featured in Hawaii Home + Remodeling",
        categoryUID: "pva-in-the-news",
        description: "Creating Calm \u2013 A Kahala property is reimagined, evoking a sense of peace through symmetrical design, water elements and fire features.",
        date: "July 2019",
        imageURL: "https://images.prismic.io/pvadev/623baaf1d0ee103ec93dae80dcb49cc51fc104db_0-kahala-crossing.jpg?auto=compress,format",
        parentName: "News"
      });
    }
  }, {
    name: 'News post content',
    slices: ['PageBodyText', 'PageBodySmallImage', 'PageBodyText'],
    recommendedSlices: ['PageBodyText', 'PageBodySmallImage'],
    reasoning: 'News post content containing text, links, and images.',
    render: function render() {
      return React.createElement(PageBodyText, {
        textHTML: "<p>The program is small and highly selective, helping to develop future leaders in the architectural profession\u2014not only in practice, but also in their communities.</p><p>The AIA Leadership Academy is a three-year program designed for architects who are ready for the next level of leadership in their firm, profession, and community. They learn critical business and leadership skills to grow their business, lead change and innovation, empower and engage people, and create immediate impact.</p>"
      });
    }
  }, {
    name: 'News',
    preset: true,
    reasoning: 'Links to the next and previous news posts. Also links related projects if provided.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyPrevNext, {
        prevLabel: "Pacific Business News Cover Story: Welcome to the Club [House]",
        prevHref: true,
        nextLabel: "PVA wins big at AIA Honolulu Awards",
        nextHref: true,
        variant: "news"
      }), React.createElement(PageBodyProjectCards, {
        listName: "Related Projects"
      }, React.createElement(PageBodyProjectCards.Project, {
        variant: "wide",
        categoryUID: "residential",
        imageURL: "https://images.prismic.io/pvadev/623baaf1d0ee103ec93dae80dcb49cc51fc104db_0-kahala-crossing.jpg?auto=compress,format",
        location: "Honolulu, Hawaii",
        name: "Kahala Crossroads"
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};