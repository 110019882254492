import React from 'react';
import { PageBodyFeaturedProjects } from 'src/slices/PageBodyFeaturedProjects';
import { PageBodyBrandedCallout } from 'src/slices/PageBodyBrandedCallout';
import { PageBodyMixedSizedProjects } from 'src/slices/PageBodyMixedSizedProjects';
import { PageBodySmallProjects } from 'src/slices/PageBodySmallProjects';
import { PageBodyHorizontalNavigation } from 'src/slices/PageBodyHorizontalNavigation';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var design = {
  name: 'Work',
  customType: 'page',
  description: "A dedicated layout for showcasing the breadth of PVA's projects.",
  example: [['PageBodyHorizontalNavigation', 0], ['PageBodyFeaturedProjects', 0], ['PageBodyMixedSizedProjects', 0], ['PageBodyBrandedCallout', 0], ['PageBodySmallProjects', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'Horizontal Navigation',
    preset: true,
    reasoning: 'Provides quick access to different project category pages.',
    render: function render() {
      return React.createElement(PageBodyHorizontalNavigation, {
        title: "work",
        rootHref: "/",
        nextSharesBg: [true]
      }, React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Hospitality + Leisure"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Interior Design"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Office + Healthcare"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Residential"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Retail + Restaurants"));
    }
  }, {
    name: 'Project Grid',
    slices: ['PageBodyFeaturedProjects', 'PageBodyMixedSizedProjects', 'PageBodyBrandedCallout', 'PageBodySmallProjects'],
    recommendedSlices: ['PageBodyFeaturedProjects', 'PageBodyMixedSizedProjects', 'PageBodySmallProjects', 'PageBodyBrandedCallout'],
    reasoning: 'Varied usage of the recommended slices helps give the layout a dynamic flow. The callout helps to provide a break in the list of projects.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyFeaturedProjects, {
        nextSharesBg: [true]
      }, React.createElement(PageBodyFeaturedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/541704ba04a3f58caa4a37dd325d793d351dbd1d_0-flower-avenue.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Flower Avenue",
        href: true
      }), React.createElement(PageBodyFeaturedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Sushi Izakaya Dining: Shinn",
        href: true
      })), React.createElement(PageBodyMixedSizedProjects, {
        nextSharesBg: [true]
      }, React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/6bcaca475792334832ae3d835e00524e8c0ddb2c_0-benihana-of-tokyo-at-hilton-hawaiian-village.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Flower Avenue",
        href: true,
        gridRow: [1, 1, '1 / span 2'],
        gridColumn: [null, '1 / span 2'],
        innerProps: {
          height: '100%'
        }
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Sushi Izakaya Dining: Shinn",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/0beb8c86ebf30eda62f806418c822ef711871eea_0-hale-palekaiko.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Hale Palekaiko",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dba11a47caa75670e5d319fac411d112d5823ada_0-mokulua-hillside-renovation.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Mokulua Hillside Renovation",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/20309d3ee037fcd91451100fc6a57954af20caad_0-assaggio-ristorante.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Assaggio Ristorante",
        href: true
      })), React.createElement(PageBodyBrandedCallout, {
        variant: "dark",
        textHTML: "<h1>Our team of architects and interior designers is both collaborative and visionary.</h1>",
        buttonHref: true,
        buttonText: "Our approach",
        nextSharesBg: [true]
      }), React.createElement(PageBodySmallProjects, {
        nextSharesBg: [true]
      }, React.createElement(PageBodySmallProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Sushi Izakaya Dining: Shinn",
        href: true
      }), React.createElement(PageBodySmallProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/0beb8c86ebf30eda62f806418c822ef711871eea_0-hale-palekaiko.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Hale Palekaiko",
        href: true
      }), React.createElement(PageBodySmallProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dba11a47caa75670e5d319fac411d112d5823ada_0-mokulua-hillside-renovation.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Mokulua Hillside Renovation",
        href: true
      }), React.createElement(PageBodySmallProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/20309d3ee037fcd91451100fc6a57954af20caad_0-assaggio-ristorante.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Assaggio Ristorante",
        href: true
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};