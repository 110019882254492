import React from 'react';
import lorem from 'fast-lorem-ipsum';
import { PageBodyFloatingNavigation } from 'src/slices/PageBodyFloatingNavigation';
import { PageBodyService } from 'src/slices/PageBodyService';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var services = {
  name: 'Services',
  customType: 'page',
  description: "Showcase PVA's breadth of services with project examples and descriptions.",
  example: [['PageBodyFloatingNavigation', 0], ['PageBodyService', 0], ['PageBodyService', 1], ['PageBodyService', 0], ['PageBodyService', 1], ['PageBodyService', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'Floating Navigation',
    preset: true,
    reasoning: 'Anchor based navigation lets visitors quickly jump around to different sections of the page.',
    render: function render() {
      return React.createElement(PageBodyFloatingNavigation, {
        title: "services",
        rootHref: "/",
        nextSharesBg: [true]
      }, React.createElement(PageBodyFloatingNavigation.Item, {
        href: "/"
      }, "Architecture"), React.createElement(PageBodyFloatingNavigation.Item, {
        href: "/"
      }, "Interior Design"), React.createElement(PageBodyFloatingNavigation.Item, {
        href: "/"
      }, "Signage + Graphics"), React.createElement(PageBodyFloatingNavigation.Item, {
        href: "/"
      }, "Consulting"), React.createElement(PageBodyFloatingNavigation.Item, {
        href: "/"
      }, "Physical Modeling"));
    }
  }, {
    name: 'Services List',
    slices: ['PageBodyService', 'PageBodyService', 'PageBodyService', 'PageBodyService', 'PageBodyService'],
    recommendedSlices: ['PageBodyService'],
    reasoning: 'Alternating the side of the images and text helps maintain a varied layout despite using the same slice.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyService, {
        title: "Architecture",
        spacerHR: true,
        textAlign: "left",
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/91184ab98a472d86b418264c12d683d58a6eb44d_e212ba4a167b5f008e0b26191968198d7804e9ea.png",
        description: "<p>Peter Vincent Architects provides the full range of architectural services including design services for new buildings and homes, tenant build-out, remodeling and interior architecture.</p><p>Peter Vincent Architects is registered in California, Nevada, Colorado, Hawaii, Massachusetts, and Japan and is certified by the National Council of Architectural Registration Boards (NCARB).</p>"
      }, React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "office-healthcare",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/e1d47f8f8ffe39330c19d3a01ccc34b4c59bb4b4_0-bank-of-hawaii.jpg",
        location: "Honolulu, Hawaii",
        name: "Bank of Hawaii"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "residential",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/572844f3e2e1fadb33dabd0253b031d48ce32c36_0-makiki-modern-makeover.jpg",
        location: "Honolulu, Hawaii",
        name: "Makiki Modern Makeover"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "hospitality-leisure",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dae0d171c7d1f15f5d309c504fec3488b8907178_0-kapolei-golf-course-clubhouse-renovation.jpg",
        location: "Honolulu, Hawaii",
        name: "Kapolei Golfcourse Clubhouse Renovation"
      })), React.createElement(PageBodyService, {
        title: "Interior Design",
        spacerHR: true,
        textAlign: "right",
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/8337691f72f5dd6901b6f5f1a5510b5555111132_pva_alstonhome2166kitchenone_web.jpg",
        description: "<p>" + lorem('35w') + "</p>"
      }, React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "hospitality-leisure",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/d91886ef3314e6f0267f5a9cc58c229f0e2956ac_0-ko-a-kea-hotel---resort.jpg",
        location: "Poipu Beach, Kauai",
        name: "Ko'a Kea Hotel & Resoirt"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "interior-design",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/01ca77dc25441a41fc064055c7e39b7dba0dca60_0-milici-valenti-ng-pack.jpg",
        location: "Honolulu, Hawaii",
        name: "Milici Valenti Ng Pack"
      })), React.createElement(PageBodyService, {
        title: "Signage + Graphics",
        spacerHR: true,
        textAlign: "left",
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/b58972599575739d448dc31f93f8c8b9a62ec528_3dba6971863c05ec6b7d8e23588e3d3ac68ec97f.png",
        description: "<p>" + lorem('35w') + "</p>"
      }, React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "retail-restaurants",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/a7bcf6738bdb182e9dfb60a7d7a0ba7899bba323_0-dondon.jpg",
        location: "Honolulu, Hawaii",
        name: "Dondon"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "retail-restaurants",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/6322828d53b94f594e137d67fc133b6e5cc71a37_0-sobaya-japanese-noodle-factory.jpg",
        location: "Honolulu, Hawaii",
        name: "Sobaya"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "interior-design",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/764106413a69f9706b1342a274942cc4d5eff58c_0-saint-germain-bakery.jpg",
        location: "Kahala Times Supermarket",
        name: "Saint-Germain Bakery"
      })), React.createElement(PageBodyService, {
        title: "Consulting",
        spacerHR: true,
        textAlign: "right",
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/272db5a7cc9f4c1516d748d46cd166e299adc09e_fpo.png",
        description: "<p>" + lorem('35w') + "</p>"
      }, React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "residential",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/1a73c08be893346994eceeec91a594864bf29ce9_0-ka-apuni-beach-estate.jpg",
        location: "Kailua, Hawaii",
        name: "Ka'apuni Beach Estate"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "interior-design",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/461ab719dc4d1206304fab56ea40b191658b0816_0-local-motion-flagship-store.jpg",
        location: "Waikiki, Hawaii",
        name: "Local Motion Flagship Store"
      }), React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "retail-restaurants",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/19b7543c5ed093b15f691178bd620c2a19d1c40e_0-crossroads-at-kapolei-shopping-center.jpg",
        location: "Kapolei, Hawaii",
        name: "Crossroads at Kapolei Shopping Center"
      })), React.createElement(PageBodyService, {
        title: "Physical Modeling",
        spacerHR: true,
        textAlign: "left",
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/91184ab98a472d86b418264c12d683d58a6eb44d_e212ba4a167b5f008e0b26191968198d7804e9ea.png",
        description: "<p>" + lorem('35w') + "</p>",
        buttonLink: true,
        buttonText: "Go to site",
        nextSharesBg: [true]
      }, React.createElement(PageBodyService.FeaturedProject, {
        variant: "wideSmall",
        categoryUID: "residential",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/e894212dcdb76f93d55eba2b2a4b6ab71c05301f_0-kahala-oceanfront-estate.jpg",
        name: "Kahala Oceanfront Estate"
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};