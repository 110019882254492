import React from 'react';
import lorem from 'fast-lorem-ipsum';
import { PageBodyHorizontalNavigation } from 'src/slices/PageBodyHorizontalNavigation';
import { PageBodyIntroText } from 'src/slices/PageBodyIntroText';
import { PageBodyText } from 'src/slices/PageBodyText';
import { PageBodyImages } from 'src/slices/PageBodyImages';
import { PageBodyImageWithText } from 'src/slices/PageBodyImageWithText';
import { PageBodyBrandedCallout } from 'src/slices/PageBodyBrandedCallout';
import { PageBodyProjectCards } from 'src/slices/PageBodyProjectCards';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var about = {
  name: 'About',
  customType: 'page',
  description: 'Highlight key information about PVA as a studio.',
  example: [['PageBodyHorizontalNavigation', 0], ['PageBodyIntroText', 0], ['PageBodyImages', 0], ['PageBodyText', 0], ['PageBodyText', 0], ['PageBodyImageWithText', 0], ['PageBodyBrandedCallout', 1], ['PageBodyProjetCards', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'Introductory Text',
    preset: true,
    reasoning: 'The Intro Text preset provides provides context to the rest of the content on the page as well as navigation.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyHorizontalNavigation, {
        title: "about",
        rootHref: "/",
        nextSharesBg: [true]
      }, React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "About PVA"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Team")), React.createElement(PageBodyIntroText, {
        introText: "<p>Introduction regarding this page. " + lorem('15w') + "</p>"
      }));
    }
  }, {
    name: 'Page Content',
    slices: ['PageBodyImages', 'PageBodyText', 'PageBodyText', 'PageBodyImageWithText'],
    recommendedSlices: ['PageBodyImages', 'PageBodyText', 'PageBodyText', 'PageBodyImageWithText'],
    reasoning: 'Starting with a large image helps create viewer interest. Ensure a mixture of text and image slices to keep readers engaged.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyImages, {
        nextSharesBg: [true]
      }, React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/d3dce7f4e7db0c7f378d439a6ea9ab6acede641d_pva_sketch.jpg"
      })), React.createElement(PageBodyText, {
        textHTML: "<h1>Our History</h1><p>Peter Vincent, FAIA founded Peter Vincent Architects in 1992, and was joined by partners Michael Subiaga in 2007 and Max Guenther in 2009. The firm has steadily grown in size and expertise, and is recognized as one of Hawaii's most highly regarded architecture firms.</p><p>PVA's experience has also expanded globally, with projects stretching from Hawaii and the Pacific Rim to North America, Europe, the Middle East and Asia. Together, we have assembled a team of architects and interior designers that is both collaborative and visionary.</p>"
      }), React.createElement(PageBodyText, {
        textHTML: "<h1>Our Vision</h1><p>PVA's work encompasses a broad spectrum of styles and genres, responding to the unique needs and vision of each client as well as the physical, social and environmenta l opportunities and requirements offered by each site.</p><p>We are proud to call Chinatown our home\u2014the revival of this historic section of Honolulu is attributed to businesses that invest in its redevelopment and in a healthy future.</p>"
      }), React.createElement(PageBodyImageWithText, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/272db5a7cc9f4c1516d748d46cd166e299adc09e_fpo.png",
        textHTML: "<h1>Process &amp; Philosphy</h1><p>" + lorem('20w') + "</p>"
      }));
    }
  }, {
    name: 'Callout',
    slices: ['PageBodyBrandedCallout', 'PageBodyProjectCards'],
    recommendedSlices: ['PageBodyBrandedCallout', 'PageBodyProjectCards'],
    reasoning: 'Ending the page with these slices prompts the reader to explore additional projects and pages on the site.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyBrandedCallout, {
        variant: "dark",
        textHTML: "<h1>Our team of architects and interior designers is both collaborative and visionary.</h1>",
        buttonHref: true,
        backgroundImageUrl: "https://pvadev.cdn.prismic.io/pvadev/6b7ab5be16470761c51f3a9961d9ec58d83a8366_office.jpg",
        buttonText: "Meet the team",
        nextSharesBg: [true]
      }), React.createElement(PageBodyProjectCards, {
        listName: "Recent Projects",
        nextSharesBg: [true]
      }, React.createElement(PageBodyProjectCards.Project, {
        variant: "wide",
        categoryUID: "office-healthcare",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/e1d47f8f8ffe39330c19d3a01ccc34b4c59bb4b4_0-bank-of-hawaii.jpg",
        name: "Bank of Hawaii",
        location: "Honolulu, Hawaii"
      }), React.createElement(PageBodyProjectCards.Project, {
        variant: "wide",
        categoryUID: "hospitality-leisure",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dae0d171c7d1f15f5d309c504fec3488b8907178_0-kapolei-golf-course-clubhouse-renovation.jpg",
        name: "Kapolei Golf Course Clubhouse Renovation",
        location: "Kapolei, Hawaii"
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};