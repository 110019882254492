import React from 'react';
import { PageBodyHorizontalNavigation } from 'src/slices/PageBodyHorizontalNavigation';
import { PageBodyFeaturedNewsPost } from 'src/slices/PageBodyFeaturedNewsPost';
import { PageBodyLatestNewsPosts } from 'src/slices/PageBodyLatestNewsPosts';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var news = {
  name: 'News',
  customType: 'page',
  description: 'Lists news posts with an optionally featured article at the top of the page.',
  example: [['PageBodyHorizontalNavigation', 0], ['PageBodyFeaturedNewsPost', 0], ['PageBodyLatestNewsPosts', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'News Category',
    preset: true,
    reasoning: 'Links to news category pages with filtered articles.',
    render: function render() {
      return React.createElement(PageBodyHorizontalNavigation, {
        title: "news",
        rootHref: "/",
        nextSharesBg: [true]
      }, React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "PVA in the News"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Company Updates"));
    }
  }, {
    name: 'Featured News Post',
    slices: ['PageBodyFeaturedNewsPost'],
    recommendedSlices: ['PageBodyFeaturedNewsPost'],
    reasoning: 'Feature a news post with a large image and introduction to the article.',
    render: function render() {
      return React.createElement(PageBodyFeaturedNewsPost, {
        title: "Kahala Crossings featured in Hawaii Home + Remodeling",
        description: "Creating Calm \u2013 A Kahala property is reimagined, evoking a sense of peace through symmetrical design, water elements and fire features.",
        imageURL: "https://images.prismic.io/pvadev/623baaf1d0ee103ec93dae80dcb49cc51fc104db_0-kahala-crossing.jpg?auto=compress,format",
        href: true,
        categoryUID: "pva-in-the-news",
        date: "July 2019"
      });
    }
  }, {
    name: 'News Category',
    preset: true,
    reasoning: 'Lists all news posts eight posts at a time. If a news category is selected, the news posts are filtered to the category.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyLatestNewsPosts, null), React.createElement(PageBodyFooter, null));
    }
  }]
};