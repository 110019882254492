import React from 'react';
import lorem from 'fast-lorem-ipsum';
import { PageBodyImages } from 'src/slices/PageBodyImages';
import { PageBodyText } from 'src/slices/PageBodyText';
import { PageBodyProjectIntro } from 'src/slices/PageBodyProjectIntro';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var project = {
  name: 'Project',
  customType: 'page',
  description: 'Showcase a PVA project with additional imagery and information.',
  example: [['PageBodyProjectIntro', 0], ['PageBodyText', 0], ['PageBodyImages', 0], ['PageBodyImages', 1]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        isTransparent: true
      });
    }
  }, {
    name: 'Project',
    preset: true,
    reasoning: 'Showcases the project with a large, full-width image. Provides context with stylized introductory text.',
    render: function render() {
      return React.createElement(PageBodyProjectIntro, {
        parentName: "Design",
        categoryUID: "residential",
        name: "Flower Avenue",
        description: "Introduction about the project and PVA's role in the project. " + lorem('20w'),
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/541704ba04a3f58caa4a37dd325d793d351dbd1d_0-flower-avenue.jpg",
        introImageURL: "https://pvadev.cdn.prismic.io/pvadev/2bddb6c5563f967d8cbecec6c04d14ac98257324_1-flower-avenue.jpg",
        locationHTML: "<p>Venice, California</p>"
      });
    }
  }, {
    name: 'Project Content',
    recommendedSlices: ['PageBodyText', 'PageBodyImages', 'PageBodyImageWithText'],
    reasoning: 'Any additional content and information for this project. Mixing text slices between images helps break up large quantities of images.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyText, {
        textHTML: "<p>Text about the project's successes or challenges. " + lorem('30w') + "</p>",
        variant: "wide"
      }), React.createElement(PageBodyImages, {
        nextSharesBg: [true],
        variant: "wide"
      }, React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/412d102996c3a439dcd2802a714e9b9ccdbf53af_3-flower-avenue.jpg"
      }), React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/aea6744aca4680ac3e6e1abea875bb505858b89f_4-flower-avenue.jpg"
      })), React.createElement(PageBodyImages, {
        variant: "wide",
        nextSharesBg: [true]
      }, React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/0d77aa8b835a93c8eeb0525b7f530c021cb6c073_2-flower-avenue.jpg",
        captionHTML: "<p>This is a caption.</p>"
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};