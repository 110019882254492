import React from 'react';
import lorem from 'fast-lorem-ipsum';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
import { PageBodyButtonNavigation } from 'src/slices/PageBodyButtonNavigation';
import { PageBodyMap } from 'src/slices/PageBodyMap';
export var contact = {
  name: 'Contact',
  customType: 'page',
  description: 'Display contact information and location details for PVA.',
  example: [['PageBodyButtonNavigation', 0], ['PageBodyMap', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'Button Navigation',
    preset: true,
    reasoning: 'Provides links to related and translated pages regarding contacting PVA.',
    render: function render() {
      return React.createElement(PageBodyButtonNavigation, {
        title: "contact",
        rootHref: "/",
        nextSharesBg: [true],
        buttons: [React.createElement(PageBodyButtonNavigation.ButtonItem, {
          href: "/",
          key: 0
        }, "English"), React.createElement(PageBodyButtonNavigation.ButtonItem, {
          href: "/",
          key: 1
        }, "\u65E5\u672C\u8A9E"), React.createElement(PageBodyButtonNavigation.ButtonItem, {
          href: "/",
          key: 2
        }, "Mandarin")]
      }, React.createElement(PageBodyButtonNavigation.Item, {
        href: "/"
      }, "Contact"), React.createElement(PageBodyButtonNavigation.Item, {
        href: "/"
      }, "Careers"));
    }
  }, {
    name: 'Information and Map',
    slices: ['PageBodyMap'],
    recommendedSlices: ['PageBodyMap'],
    reasoning: 'Provide contact information and additional relevant info such as parking locations.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyMap, {
        withPlaceholderMap: true,
        address: "\n              <p>\n                <strong>Peter Vincent Architects</strong></br>\n                1021 Smith Street, Penthouse</br>\n                Honolulu, Hawaii 96817\n              </p>\n            ",
        contactInformation: "\n              <p>\n                <strong>T </strong><a>808.524.8255</a></br>\n                <strong>F </strong><a>808.523.3419</a></br>\n                <strong>E </strong><a>info@pva.com</a></br>\n              </p>\n            ",
        footnote: "\n              <h1>Parking</h1>\n              <p>" + lorem('15w') + "</p>\n              <h1>Press Inquiries</h1>\n              <p>For more information about the firm or specific projects, please contact Cathy Bachl at <a>marketing@pva.com</a>.</p>\n            "
      }), React.createElement(PageBodyFooter, null));
    }
  }]
};