import React from 'react';
import { PageBodyHorizontalNavigation } from 'src/slices/PageBodyHorizontalNavigation';
import { PageBodyAwards } from 'src/slices/PageBodyAwards';
import { PageBodyFooter } from 'src/slices/PageBodyFooter';
import { PageBodyHeader } from 'src/slices/PageBodyHeader';
export var recognition = {
  name: 'Recognition',
  customType: 'page',
  description: "A layout for displaying all of PVA's awards and press recognition.",
  example: [['PageBodyHorizontalNavigation', 0], ['PageBodyAwards', 0], ['PageBodyAwards', 1], ['PageBodyAwards', 0]],
  zones: [{
    render: function render() {
      return React.createElement(PageBodyHeader, {
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'Horizontal Navigation',
    preset: true,
    reasoning: 'Links to other related pages on the site. The page title is always displayed even if no links are present.',
    render: function render() {
      return React.createElement(PageBodyHorizontalNavigation, {
        title: "recognition",
        rootHref: "/",
        nextSharesBg: [true]
      });
    }
  }, {
    name: 'List of Awards',
    slices: ['PageBodyAwards'],
    recommendedSlices: ['PageBodyAwards'],
    reasoning: 'The Awards slice can be used to list the various awards PVA has received. Alternate the sides of the project images to help break up the layout.',
    render: function render() {
      return React.createElement(React.Fragment, null, React.createElement(PageBodyAwards, {
        orgImageURL: "https://pvadev.cdn.prismic.io/pvadev/a44e80e173d52a6f118ef4ff5981fd601cdd9077_aia-bw-logo.png",
        heading: "AIA, American Institute of Architects / Honolulu Chapter",
        projectImageSide: "left",
        withDivider: true,
        projectImageURL: "https://images.prismic.io/pvadev/541704ba04a3f58caa4a37dd325d793d351dbd1d_0-flower-avenue.jpg?auto=compress,format",
        projectTitle: "Flower Avenue"
      }, React.createElement(PageBodyAwards.Award, {
        year: "2019",
        awardsHTML: "<p>\n                <strong>Hawaii Energy Award for Excellence in Energy Efficiency Design</strong> - \n                Hoakalei Golf Clubhouse\n                </p>\n                <p>\n                <strong>Honorable Mention</strong> - \n                Flower Avenue\n              </p>"
      }), React.createElement(PageBodyAwards.Award, {
        year: "2017",
        awardsHTML: "<p>\n                <strong>Honorable Mention</strong> - \n                 Hale PaleKaiko \n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2013",
        awardsHTML: "<p>\n                <strong>Award of Merit</strong> - \n                Modern Makeover\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2011",
        awardsHTML: "<p>\n                <strong>Award of Merit</strong> - \n                Sushi Izakaya Dining: Shinn\n                </p>\n                <p>\n                <strong>Award of Merit</strong> - \n                Flower Avenue\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2010",
        awardsHTML: "<p>\n                <strong>Award of Merit</strong> - \n                Ko'a kea Hotel & Resort at Po'ipu Beach\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2009",
        awardsHTML: "<p>\n                <strong>Award of Merit</strong> - \n                Na Manu Eha\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2008",
        awardsHTML: "<p>\n                <strong>Award of Merit</strong> - \n                Loree Rodkin\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2007",
        awardsHTML: "<p>\n                <strong>Award of Merit</strong> - \n                Milici Valenti Ng Pack Office Renovation<\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2006",
        awardsHTML: "<p>\n                <strong>Award of Excellence</strong> - \n                Rokkaku Authentic Japanese Restaurant \n                </p>\n                <p>\n                <strong>Member's Choice Award</strong> - \n                Rokkaku Authentic Japanese Restaurant \n                </p>\n              "
      })), React.createElement(PageBodyAwards, {
        heading: "ASID, American Society of Interior Designers Honolulu Chapter",
        orgImageURL: "https://pvadev.cdn.prismic.io/pvadev/4780168eee322d04b17b21b07c048bf107d348ad_asid-logo.png",
        withDivider: true,
        projectImageSide: "right",
        projectImageURL: "https://images.prismic.io/pvadev/f319644f61534f5c037b01757d4c651adbb55a35_0-lanikai-hillside-residence.jpg?auto=compress,format",
        projectTitle: "Lanikai Hillside Residence"
      }, React.createElement(PageBodyAwards.Award, {
        year: "2003",
        awardsHTML: "<p>\n                <strong>Grand Overall Award</strong> - \n                Lanikai Beach Grand Cottage\n                </p>\n                <p>\n                <strong>Award of Excellence/strong> - \n                Lanikai Beach Grand Cottage\n                </p>\n                <p>\n                <strong>Award of Excellence</strong> - \n                Lanikai Hillside Residence\n                </p>\n                <p>\n                <strong>Award of Honor</strong> - \n                Hilo Physician's Office\n                </p>\n              "
      })), React.createElement(PageBodyAwards, {
        heading: "Building Industry Association Renaissance Building + Remodeling Awards",
        orgImageURL: "https://pvadev.cdn.prismic.io/pvadev/6b7e3d4057f326c7789b69c2de4a2879ce08cbf3_bialogo-primary-black-noname.png",
        nextSharesBg: [true],
        projectImageSide: "left",
        projectImageURL: "https://images.prismic.io/pvadev/191e732cfadc33b9f50899c846065d4526556c30_2-kahala-thai-leed-platinum-residence.jpg?auto=compress,format",
        projectTitle: "Kahala Thai Leed Platinum"
      }, React.createElement(PageBodyAwards.Award, {
        year: "2018",
        awardsHTML: "<p>\n                <strong>Grand Overall Award</strong> - \n                Kahala Thai LEED Platinum\n                </p>\n                <p>\n                <strong>Grand Award</strong> - \n                Hale Kahakai\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2016",
        awardsHTML: "<p>\n                <strong>Overall Grand Award</strong> - \n                Makiki Modern Makeover\n                </p>\n                <p>\n                <strong>Merit Award</strong> - \n                Hale Palekaiko\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2013",
        awardsHTML: "<p>\n                <strong>Overall Grand Award</strong> - \n                Subtle Elegance\n                </p>\n              "
      }), React.createElement(PageBodyAwards.Award, {
        year: "2012",
        awardsHTML: "<p>\n                <strong>Editor's Choice Award</strong> - \n                Wailupe Circle Residence\n                </p>\n                <p>\n                <strong>Merit Award</strong> - \n                East O'ahu Kama'aina Family Home\n                </p>\n              "
      })), React.createElement(PageBodyFooter, null));
    }
  }]
};